import {
  takeLatest,
  all,
  call,
  put,
  select,
  delay,
  race,
  take,
} from "redux-saga/effects";

import history from "utils/history";
import PackageApi from "ee/api/PackageApi";
import {
  ReduxActionTypes,
  ReduxActionErrorTypes,
} from "ee/constants/ReduxActionConstants";
import { validateResponse } from "sagas/ErrorSagas";
import {
  CREATE_PACKAGE_ERROR,
  createMessage,
  ERROR_IMPORT_PACKAGE,
  FETCH_PACKAGE_ERROR,
  FETCH_PACKAGES_ERROR,
  // PACKAGE_PUBLISH_SUCCESS,
  PACKAGE_PUBLISH_SUCCESS_SIMPLE,
  PACKAGE_PULL_ERROR,
} from "ee/constants/messages";
import {
  getIsFetchingPackages,
  getPackagesList,
} from "ee/selectors/packageSelectors";
import { getNextEntityName } from "utils/AppsmithUtils";
import {
  DEFAULT_PACKAGE_COLOR,
  DEFAULT_PACKAGE_ICON,
  DEFAULT_PACKAGE_PREFIX,
} from "ee/constants/PackageConstants";
import { BASE_PACKAGE_EDITOR_PATH } from "ee/constants/routes/packageRoutes";
import type { ApiResponse } from "api/ApiResponses";
import {
  fetchConsumablePackagesInWorkspace,
  pollPackagePullStatus,
  pullPackage,
  type CreatePackageFromWorkspacePayload,
  type DeletePackagePayload,
  type FetchConsumablePackagesInWorkspacePayload,
  type FetchPackagePayload,
  type ImportPackagePayload,
  type PollPackagePullStatusPayload,
  type PublishPackagePayload,
  type PullPackagePayload,
  type RequestPullPackagePayload,
} from "ee/actions/packageActions";
import type {
  CreatePackagePayload,
  FetchPackageResponse,
  ImportPackageResponse,
  PollPackagePullStatusResponse,
  PublishPackageResponse,
  PullPackageResponse,
} from "ee/api/PackageApi";
import type {
  ReduxAction,
  ReduxActionType,
} from "ee/constants/ReduxActionConstants";
import type { Package, PackageMetadata } from "ee/constants/PackageConstants";
import { toast } from "@appsmith/ads";
import {
  getPackagePullFeature,
  getShowQueryModule,
} from "ee/selectors/moduleFeatureSelectors";
import analytics from "ee/utils/Packages/analytics";
import { setUnconfiguredDatasourcesDuringImport } from "actions/datasourceActions";
import { fetchPlugins } from "actions/pluginActions";
import {
  setIsReconnectingDatasourcesModalOpen,
  setWorkspaceIdForImport,
} from "ee/actions/applicationActions";
import { evalWorker as EvalWorker } from "utils/workerInstances";
import { EVAL_WORKER_ACTIONS } from "ee/workers/Evaluation/evalWorkerActions";
import { waitForUpdatingModuleReferences } from "./moduleSagas";
import {
  getHasCyclicModuleReference,
  getActiveModuleActions,
} from "ee/selectors/modulesSelector";
import { PACKAGE_PULL_STATUS } from "ce/constants/ModuleConstants";
import { getCurrentApplicationId } from "selectors/editorSelectors";
import type { AxiosPromise } from "axios";
import { get, omit } from "lodash";
import { ModuleInstanceCreatorType } from "ee/constants/ModuleInstanceConstants";
import { setupModuleInstanceSaga } from "./moduleInstanceSagas";
import { getCurrentWorkspaceId } from "ee/selectors/selectedWorkspaceSelectors";

interface CreatePackageSagaProps {
  workspaceId: string;
  name?: string;
  icon?: string;
  color?: string;
}

interface ShowReconnectModalProps {
  package: ImportPackageResponse["package"];
  unConfiguredDatasourceList: ImportPackageResponse["unConfiguredDatasourceList"];
}

export interface FetchPackagesForWorkspacePayload {
  workspaceId: string;
}

interface fetchAndRaceWithPackagePullOptions<TPayload> {
  payload: TPayload;
  path: "moduleInstances" | "consumables" | "entities";
}

const PUBLISH_DEPENDANT_ACTIONS = [
  ReduxActionTypes.UPDATE_MODULE_INPUTS_INIT,
  ReduxActionTypes.UPDATE_JS_FUNCTION_PROPERTY_INIT,
  ReduxActionTypes.UPDATE_MODULE_INIT,
  ReduxActionTypes.UPDATE_ACTION_INIT,
  ReduxActionTypes.SAVE_MODULE_NAME_INIT,
];

const DEFAULT_RETRY_LIMIT = 70;
const MAX_POLL_COUNT = 50;
const POLL_INTERVAL = 1000; // in ms

export function* waitUntilRunningActionsComplete(
  dependentActions: ReduxActionType[],
  retryLimit = DEFAULT_RETRY_LIMIT,
) {
  let activeActions: string[] = yield select(getActiveModuleActions);
  let currCount = 0;

  // Keep checking until all specified actions are completed
  while (dependentActions.some((action) => activeActions.includes(action))) {
    // If an operation is taking very long time or due to any bug, the completion
    // status is not dispatched; the retry limit will make sure to return false
    // for the caller saga to take appropriate action.
    if (currCount > retryLimit) {
      return false;
    }

    currCount++;

    yield delay(100); // Wait for a short delay before checking again
    activeActions = yield select(getActiveModuleActions); // Recheck the actions
  }

  return true;
}

export function* fetchPackagesForWorkspaceSaga(
  action: ReduxAction<FetchPackagesForWorkspacePayload>,
) {
  try {
    const showQueryModule: boolean = yield select(getShowQueryModule);

    if (showQueryModule) {
      const response: ApiResponse = yield call(
        PackageApi.fetchPackagesByWorkspace,
        action.payload,
      );
      const isValidResponse: boolean = yield validateResponse(response);

      if (isValidResponse) {
        yield put({
          type: ReduxActionTypes.FETCH_PACKAGES_FOR_WORKSPACE_SUCCESS,
          payload: response.data,
        });
      }
    } else {
      yield put({
        type: ReduxActionTypes.FETCH_PACKAGES_FOR_WORKSPACE_SUCCESS,
        payload: [],
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.FETCH_PACKAGES_FOR_WORKSPACE_ERROR,
      payload: { error: { message: createMessage(FETCH_PACKAGES_ERROR) } },
    });
  }
}

export function* fetchConsumablePackagesInWorkspaceSaga(
  action: ReduxAction<FetchConsumablePackagesInWorkspacePayload>,
) {
  try {
    const response: ApiResponse = yield fetchAndRaceWithPackagePull(
      PackageApi.fetchConsumablePackagesInWorkspace,
      {
        payload: action.payload,
        path: "consumables",
      },
    );
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.FETCH_CONSUMABLE_PACKAGES_IN_WORKSPACE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.FETCH_CONSUMABLE_PACKAGES_IN_WORKSPACE_ERROR,
      payload: { error: { message: createMessage(FETCH_PACKAGES_ERROR) } },
    });
  }
}

/**
 * Saga creates a package and specifically should be called from workspace
 */
export function* createPackageFromWorkspaceSaga(
  action: ReduxAction<CreatePackageFromWorkspacePayload>,
) {
  try {
    const { workspaceId } = action.payload;

    const isFetchingPackagesList: boolean = yield select(getIsFetchingPackages);

    if (isFetchingPackagesList) return;

    const response: ApiResponse<Package> = yield call(createPackageSaga, {
      workspaceId,
    });
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      const { id } = response.data;

      yield put({
        type: ReduxActionTypes.CREATE_PACKAGE_FROM_WORKSPACE_SUCCESS,
        payload: response.data,
      });

      analytics.createPackage(response.data);

      history.push(`${BASE_PACKAGE_EDITOR_PATH}/${id}`);
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.CREATE_PACKAGE_FROM_WORKSPACE_ERROR,
      payload: {
        error: {
          message: createMessage(CREATE_PACKAGE_ERROR),
        },
      },
    });
  }
}

/**
 * Creates a package based on the workspaceId provided. name, icon and color are optional, so if
 * they are not provided; the saga will auto generate them.
 * For name, the saga will will look into existing packages in the workspace and generate the next
 * possible name.
 *
 * @param payload - CreatePackageSagaProps
 *  {
      workspaceId: string;
      name?: string;
      icon?: string;
      color?: string;
    }
 * @returns
 */
export function* createPackageSaga(payload: CreatePackageSagaProps) {
  try {
    const packageList: PackageMetadata[] = yield select(getPackagesList);

    const name = (() => {
      if (payload.name) return payload.name;

      const currentWorkspacePackages = packageList
        .filter(({ workspaceId }) => workspaceId === payload.workspaceId)
        .map(({ name }) => name);

      return getNextEntityName(
        DEFAULT_PACKAGE_PREFIX,
        currentWorkspacePackages,
      );
    })();

    const body: CreatePackagePayload = {
      workspaceId: payload.workspaceId,
      name,
      icon: payload.icon || DEFAULT_PACKAGE_ICON,
      color: payload.color || DEFAULT_PACKAGE_COLOR,
    };

    const response: ApiResponse = yield call(PackageApi.createPackage, body);

    return response;
  } catch (error) {
    throw error;
  }
}

export function* fetchPackageSaga(payload: FetchPackagePayload) {
  try {
    const response: ApiResponse<FetchPackageResponse> = yield call(
      PackageApi.fetchPackage,
      payload,
    );
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.FETCH_PACKAGE_SUCCESS,
        payload: response.data,
      });

      return response.data;
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.FETCH_PACKAGE_ERROR,
      payload: {
        error: {
          message: createMessage(FETCH_PACKAGE_ERROR),
        },
      },
    });
  }
}

export function* updatePackageSaga(action: ReduxAction<Package>) {
  try {
    const packageData: Package = yield call(PackageApi.fetchPackage, {
      packageId: action.payload.id,
    });
    const response: ApiResponse<Package> = yield call(
      PackageApi.updatePackage,
      { ...packageData, ...action.payload },
    );
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.UPDATE_PACKAGE_SUCCESS,
        payload: response.data,
      });

      analytics.updatePackage(response.data);

      return response.data;
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.UPDATE_PACKAGE_ERROR,
      payload: {
        error,
      },
    });
  }
}

export function* deletePackageSaga(action: ReduxAction<DeletePackagePayload>) {
  try {
    const response: ApiResponse<Package> = yield call(
      PackageApi.deletePackage,
      action.payload,
    );
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.DELETE_PACKAGE_SUCCESS,
        payload: action.payload,
      });

      analytics.deletePackage(action.payload.id);

      return response.data;
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.DELETE_PACKAGE_ERROR,
      payload: {
        error,
      },
    });
  }
}

export function* publishPackageSaga(
  action: ReduxAction<PublishPackagePayload>,
) {
  try {
    const isUpdatingModuleReferencesSuccess: boolean = yield call(
      waitForUpdatingModuleReferences,
    );

    if (!isUpdatingModuleReferencesSuccess) {
      return;
    }

    // Wait for the dependent actions to complete
    const success: boolean = yield call(
      waitUntilRunningActionsComplete,
      PUBLISH_DEPENDANT_ACTIONS,
    );

    if (!success) {
      throw new Error(
        "Publish failed due to pending module updates. Retry after some time or refresh",
      );
    }

    const hasCyclicModuleReference: boolean = yield select(
      getHasCyclicModuleReference,
    );

    if (hasCyclicModuleReference) {
      throw new Error("Cannot publish package with cyclic dependencies.");
    }

    const response: ApiResponse<PublishPackageResponse> = yield call(
      PackageApi.publishPackage,
      action.payload,
    );
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.PUBLISH_PACKAGE_SUCCESS,
        payload: {
          ...response.data,
          packageId: action.payload.packageId,
        },
      });

      analytics.publishPackage(action.payload.packageId);
      toast.show(createMessage(PACKAGE_PUBLISH_SUCCESS_SIMPLE), {
        kind: "success",
      });
      // toast.show(
      //   createMessage(
      //     PACKAGE_PUBLISH_SUCCESS,
      //     response.data.lastPublishedVersion,
      //   ),
      //   { kind: "success" },
      // );

      return response.data;
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.PUBLISH_PACKAGE_ERROR,
      payload: {
        error,
      },
    });
  }
}

export function* importPackageSaga(action: ReduxAction<ImportPackagePayload>) {
  try {
    const response: ApiResponse<ImportPackageResponse> = yield call(
      PackageApi.importPackage,
      action.payload,
    );

    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      const {
        isPartialImport,
        package: pkg,
        unConfiguredDatasourceList,
      } = response.data;

      yield put({
        type: ReduxActionTypes.IMPORT_PACKAGE_SUCCESS,
        payload: pkg,
      });

      if (isPartialImport) {
        yield call(showReconnectDatasourceModalSaga, {
          package: pkg,
          unConfiguredDatasourceList,
        });
      } else {
        if (!action.payload.packageId) {
          const packageUrl = `${BASE_PACKAGE_EDITOR_PATH}/${pkg.id}`;

          history.push(packageUrl);
        } else {
          yield call(fetchPackageSaga, {
            packageId: pkg.id,
          });
        }

        toast.show("Package imported successfully", {
          kind: "success",
        });
      }
    }
  } catch (e) {
    yield put({
      type: ReduxActionErrorTypes.IMPORT_PACKAGE_ERROR,
      payload: {
        error: createMessage(ERROR_IMPORT_PACKAGE),
      },
    });
  }
}

export function* showReconnectDatasourceModalSaga(
  props: ShowReconnectModalProps,
) {
  const { package: pkg, unConfiguredDatasourceList } = props;
  const { workspaceId } = pkg;

  yield put(fetchPlugins({ workspaceId }));
  yield put(
    setUnconfiguredDatasourcesDuringImport(unConfiguredDatasourceList || []),
  );
  yield put(setWorkspaceIdForImport({ editorId: pkg.id, workspaceId }));
  yield put(setIsReconnectingDatasourcesModalOpen({ isOpen: true }));
}

/**
 * For now this saga only supports initializing default js libs provided by the platform
 */
export function* fetchJSLibrariesSaga() {
  yield call(EvalWorker.request, EVAL_WORKER_ACTIONS.LOAD_LIBRARIES);
}

export function* requestPullPackageSaga(
  action: ReduxAction<RequestPullPackagePayload>,
) {
  try {
    const { packagePullStatus, pageId } = action.payload;
    const packagePullEnabled: boolean = yield select(getPackagePullFeature);

    if (packagePullEnabled) {
      const applicationId: string = yield select(getCurrentApplicationId);

      switch (packagePullStatus) {
        case PACKAGE_PULL_STATUS.UPGRADABLE:
          yield put(
            pullPackage({
              applicationId,
              pageId,
            }),
          );
          break;
        case PACKAGE_PULL_STATUS.UPGRADING:
          yield put(
            pollPackagePullStatus({
              applicationId,
              pageId,
            }),
          );
          break;
        default:
          break;
      }
    }
  } catch (e) {
    yield put({
      type: ReduxActionErrorTypes.PULL_PACKAGE_ERROR,
      payload: {
        error: createMessage(PACKAGE_PULL_ERROR),
      },
    });
  }
}

export function* pollPackagePullStatusSaga(
  action: ReduxAction<PollPackagePullStatusPayload>,
) {
  try {
    const {
      applicationId,
      maxPollCount = MAX_POLL_COUNT,
      pageId,
    } = action.payload;

    let pollCount = 0;
    let response: ApiResponse<PollPackagePullStatusResponse> | null = null;

    const workspaceId: string = yield select(getCurrentWorkspaceId);

    while (pollCount < maxPollCount) {
      response = yield call(PackageApi.pollPackagePullStatus, {
        applicationId,
      });

      const isValidResponse: boolean = yield validateResponse(response);

      if (response && isValidResponse) {
        if (response.data === PACKAGE_PULL_STATUS.UPGRADING) {
          pollCount++;
          yield delay(POLL_INTERVAL);
        } else {
          break;
        }
      }
    }

    if (response && response.data === PACKAGE_PULL_STATUS.UPGRADING) {
      yield put({
        type: ReduxActionErrorTypes.PULL_PACKAGE_ERROR,
        payload: {
          error: {
            message: createMessage(PACKAGE_PULL_ERROR),
          },
        },
      });
    } else {
      yield put(
        fetchConsumablePackagesInWorkspace({
          workspaceId,
          applicationId,
        }),
      );
      yield call(setupModuleInstanceSaga, {
        type: ReduxActionTypes.SETUP_MODULE_INSTANCE_INIT,
        payload: {
          contextId: pageId,
          contextType: ModuleInstanceCreatorType.PAGE,
          viewMode: false,
        },
      });

      yield put({
        type: ReduxActionTypes.POLL_PACKAGE_PULL_STATUS_SUCCESS,
      });
    }
  } catch (e) {
    yield put({
      type: ReduxActionErrorTypes.POLL_PACKAGE_PULL_STATUS_ERROR,
      payload: {
        error: {
          message: createMessage(PACKAGE_PULL_ERROR),
        },
      },
    });
  }
}

export function* pullPackageSaga(action: ReduxAction<PullPackagePayload>) {
  try {
    const response: ApiResponse<PullPackageResponse> = yield call(
      PackageApi.pullPackage,
      action.payload,
    );
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.PULL_PACKAGE_SUCCESS,
        payload: response,
      });
    }
  } catch (e) {
    yield put({
      type: ReduxActionErrorTypes.PULL_PACKAGE_ERROR,
      payload: {
        error: {
          message: createMessage(PACKAGE_PULL_ERROR),
        },
      },
    });
  }
}

export function* fetchAndRaceWithPackagePull<TPayload, TResponse>(
  apiFn: (payload: TPayload) => Promise<AxiosPromise<ApiResponse<TResponse>>>,
  options: fetchAndRaceWithPackagePullOptions<TPayload>,
) {
  const { path, payload } = options;
  const { apiResponse, packagePullResponse } = yield race({
    // Run the API call saga (for sagaA or sagaB)
    apiResponse: call(apiFn, payload),

    // Wait for packagePull to complete. If packagePull never runs, this never resolves first.
    packagePullResponse: take(ReduxActionTypes.PULL_PACKAGE_SUCCESS),
  });

  if (packagePullResponse) {
    const pullResponse: ApiResponse<PullPackageResponse> =
      packagePullResponse.payload;
    // If packagePull completed first, get the latest state and use that for the success action
    const data = get(pullResponse.data, path);
    const response = omit(pullResponse, "payload");

    return {
      ...response,
      data,
    };
  } else {
    // Otherwise, proceed with the normal apiResponse
    return apiResponse;
  }
}

export default function* packagesSaga() {
  yield all([
    takeLatest(
      ReduxActionTypes.FETCH_PACKAGES_FOR_WORKSPACE_INIT,
      fetchPackagesForWorkspaceSaga,
    ),
    takeLatest(
      ReduxActionTypes.FETCH_CONSUMABLE_PACKAGES_IN_WORKSPACE_INIT,
      fetchConsumablePackagesInWorkspaceSaga,
    ),
    takeLatest(
      ReduxActionTypes.CREATE_PACKAGE_FROM_WORKSPACE_INIT,
      createPackageFromWorkspaceSaga,
    ),
    takeLatest(ReduxActionTypes.UPDATE_PACKAGE_INIT, updatePackageSaga),
    takeLatest(ReduxActionTypes.DELETE_PACKAGE_INIT, deletePackageSaga),
    takeLatest(ReduxActionTypes.PUBLISH_PACKAGE_INIT, publishPackageSaga),
    takeLatest(ReduxActionTypes.IMPORT_PACKAGE_INIT, importPackageSaga),
    takeLatest(
      ReduxActionTypes.FETCH_JS_LIBRARIES_FOR_PKG_INIT,
      fetchJSLibrariesSaga,
    ),
    takeLatest(
      ReduxActionTypes.REQUEST_PULL_PACKAGE_INIT,
      requestPullPackageSaga,
    ),
    takeLatest(ReduxActionTypes.PULL_PACKAGE_INIT, pullPackageSaga),
    takeLatest(
      ReduxActionTypes.POLL_PACKAGE_PULL_STATUS_INIT,
      pollPackagePullStatusSaga,
    ),
  ]);
}
