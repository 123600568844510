import identity from "lodash/identity";
import type { Message } from "../../component/types";
import * as MessageCitationMapper from "./messageCitationMapper";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MessageDto = any;

export const fromDto = (messages: MessageDto[]): Message[] => {
  return messages.map((message: MessageDto) => {
    return {
      createdAt: message.createdAt,
      id: message.id,
      content: message.message[0].text.value,
      role: message.role,
      citations:
        message.citations
          ?.map(MessageCitationMapper.fromDto)
          .filter(identity) || [],
    };
  });
};
