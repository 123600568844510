import React, { useState } from "react";
import { default as CE_usePluginActionResponseTabs } from "ce/PluginActionEditor/components/PluginActionResponse/hooks/usePluginActionResponseTabs";
import { PluginType } from "entities/Action";
import { usePluginActionContext } from "PluginActionEditor";
import { useSelector } from "react-redux";
import { getIsAnvilEnabledInCurrentApplication } from "layoutSystems/anvil/integrations/selectors";
import { DEBUGGER_TAB_KEYS } from "components/editorComponents/Debugger/constants";
import { RagDocuments } from "ee/components/formControls/RagDocuments";
import type { Datasource } from "entities/Datasource";

function usePluginActionResponseTabs() {
  const [ragDocumentsCount, setRagDocumentsCount] = useState<number>();
  const { datasource, plugin } = usePluginActionContext();
  const isAnvilEnabled = useSelector(getIsAnvilEnabledInCurrentApplication);

  // For AI plugin and if anvil is enabled, we replace the datasource tab with the date tab includes RagDocuments
  if ([PluginType.AI].includes(plugin.type) && isAnvilEnabled) {
    const tabs = CE_usePluginActionResponseTabs();

    return tabs.map((tab) => {
      if (tab.key === DEBUGGER_TAB_KEYS.DATASOURCE_TAB) {
        return {
          key: DEBUGGER_TAB_KEYS.DATA_TAB,
          title: "Data",
          count: ragDocumentsCount,
          panelComponent: (
            <RagDocuments
              datasourceId={(datasource as Datasource)?.id}
              isDeletedAvailable={false}
              setCount={setRagDocumentsCount}
              workspaceId={datasource?.workspaceId}
            />
          ),
        };
      }

      return tab;
    });
  }

  return CE_usePluginActionResponseTabs();
}

export default usePluginActionResponseTabs;
