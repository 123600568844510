import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import type { ReduxAction } from "ee/constants/ReduxActionConstants";
import type { RagDocument } from "ee/components/formControls/RagDocuments/types";

export const setRagDocuments = (
  payload: RagDocument[],
): ReduxAction<RagDocument[]> => ({
  type: ReduxActionTypes.SET_RAG_DOCUMENTS,
  payload,
});
